export const SETTINGS_HEADER_TABLE = {
    trafficSource: 'trafficSource',
    trafficSourceParameters: 'trafficSourceParameters',
    vendor: 'vendor',
    buyer: 'buyer',
    buyerInvitations: 'buyerInvitations',
    vendorInvitations: 'vendorInvitations',
    destination: 'destination',
    campaignIncludeDestination: 'campaignIncludeDestination',
    destinationsGroup: 'destinationsGroup',
    destinationGroupParameters: 'destinationsGroupParameters',
    availableDestinationGroup: 'availableDestinationGroup',
    availableNumbersInNumberPool: 'availableNumbersInNumberPool',
    blockNumber: 'blockNumber',
    numberPool: 'numberPool',
    numberInPool: 'numberInPool',
    manageNumber: 'manageNumber',
    campaigns: 'campaigns',
    forwardCallsTo: 'forwardCallsTo',
    forwardCallsToByRevenue: 'forwardCallsToByRevenue',
    campaignTrackingNumber: 'campaignTrackingNumbers',
    campaignAvailableTrackingNumber: 'campaignAvailableTrackingNumber',
    campaignAvailableDestination: 'campaignAvailableDestination',
    campaignAvailableGroupDestination: 'campaignAvailableGroupDestination',
    loginSecurity: 'loginSecurity',
    apiAccessToken: 'apiAccessToken',
    paymentHistory: 'paymentHistory',
    manageCards: 'manageCards',
    callLog: 'callLog',
    workspaceActivity: 'workspaceActivity',
    reporting: 'reporting',
    callFlows: 'callFlows',
    voipShield: 'voipShield',
    blockedCarrier: 'blockedCarrier',
    callFlowAvailableDestinations: 'callFlowAvailableDestinations',
    callFlowAvailableGroups: 'callFlowAvailableGroups',
    detailGoogleIntegrationTrafficSource: 'detailGoogleIntegrationTrafficSource',
    detailWebhookIntegration: 'detailWebhookIntegration',
    googleConversation: 'googleConversation',
}
