import { ROUTES } from '@/constants/routes'

export const GLOBAL_SEARCH_TYPES = {
    vendors: {
        name: 'Vendor',
        style: '#636569',
        route: ROUTES.detailVendor,
    },
    buyers: {
        name: 'Buyer',
        style: '#DE7C6A',
        route: ROUTES.detailBuyer,
    },
    campaigns: {
        name: 'Campaign',
        style: '#424242',
        route: ROUTES.detailCampaigns,
    },
    destination_groups: {
        name: 'Group',
        style: '#157478',
        route: ROUTES.detailDestinationGroup,
    },
    phone_numbers: {
        name: 'Number',
        style: '#31435E',
        route: ROUTES.manageNumbers,
        key: true,
    },
    blocked_numbers: {
        name: 'Blocked',
        style: '#F06269',
        route: ROUTES.blockNumber,
    },
    destinations: {
        name: 'Destination',
        style: '#B4A6CF',
        route: ROUTES.detailDestination,
    },
    traffic_sources: {
        name: 'Traffic source',
        style: '#588DC3',
        route: ROUTES.detailTrafficSource,
    },
    phone_number_pools: {
        name: 'Number pool',
        style: '#878B8F',
        route: ROUTES.detailPool,
        key: true,
    },
    number_in_pool: {
        name: 'Number in pool',
        style: '#205922',
        route: ROUTES.detailPool,
        key: true,
    },
    ivrs: {
        name: 'Call Flow',
        style: '#3392D7',
        route: ROUTES.detailCallFlows,
    },
}
